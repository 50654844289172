@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* /*
 *  STYLE 1
 */

#scrollbar-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    height: 2px;
    margin-left: 400px;
    margin-right: 400px;
    background-color: #f5f5f5;
}

#scrollbar-1::-webkit-scrollbar {
    height: 2px;

    background-color: #f5f5ff;
}

#scrollbar-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

/* /*
 *  STYLE 2
 */

/* width */
#scrollbar-2::-webkit-scrollbar {
    width: 4px;
}

/* Track */
#scrollbar-2::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
#scrollbar-2::-webkit-scrollbar-thumb {
    background: rgb(161, 161, 161);
}

/* Handle on hover */
#scrollbar-2::-webkit-scrollbar-thumb:hover {
    background: rgb(121, 120, 120);
}

/* /*
 *  STYLE 3
 */

/* width */
#scrollbar-3::-webkit-scrollbar {
    width: 2px;
}

/* Track */
#scrollbar-3::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
#scrollbar-3::-webkit-scrollbar-thumb {
    background: rgb(161, 161, 161);
}

/* Handle on hover */
#scrollbar-3::-webkit-scrollbar-thumb:hover {
    background: rgb(121, 120, 120);
}

button {
    -webkit-tap-highlight-color: transparent;
}

a {
    -webkit-tap-highlight-color: transparent;
}

@layer base {
    html {
        font-family: Inter, system-ui, sans-serif;
    }
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

/* hide scrollbar class */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* For teams pagination active page */
.active-page-border {
    border-color: #2c71f6 !important;
}

.selected-icon path {
    stroke: #2c71f6;
}
